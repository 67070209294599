import { createApp } from "vue";
import { createPinia } from "pinia";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { Notification } from "@arco-design/web-vue";
import { Message } from "@arco-design/web-vue";
import * as Sentry from "@sentry/vue";

import { router } from "@/router";
import App from "@/App.vue";
import "./utils/validate";

dayjs.locale("zh-cn");

const app = createApp(App);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5,
    environment: import.meta.env.MODE,
    debug: globalThis.SENTRY_DEBUG === true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        staleTime: 0,
        refetchInterval: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    },
  },
});

Notification._context = app._context;
Message._context = app._context;

const main = async () => {
  await Promise.all([
    import("normalize.css"),
    import("@/styles/index.scss"),
    import("@peacebird/arco-design-vue-plus/dist/style.css"),
    import("@arco-design/web-vue/es/index.less"),
  ]);
  if (import.meta.env.MODE?.includes("mask")) {
    await Promise.all([import("@/styles/variable-red.scss")]);
  } else {
    await Promise.all([import("@/styles/variable.scss")]);
  }
  await router.isReady();
  app.mount("#app");
};

main();
