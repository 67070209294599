<template>
  <RouterView v-slot="{ Component }">
    <FullLoader v-if="isLoading" />
    <component v-else :is="Component" />
  </RouterView>
</template>

<script lang="ts" setup>
import { ref, shallowRef, watchEffect } from "vue";
import { RouterView } from "vue-router";
import { useGlobalStore } from "@/store/globalStore";
import { useUserStore } from "@/store/userStore";
import { FullLoader } from "./components/FullLoader";

const isLoading = ref(false);
const userStore = useUserStore();
const globalStore = useGlobalStore();
const params = new URLSearchParams(window.location.search);
const scene = params.get("app_scene");

if (scene) {
  globalStore.SET_SCENE(scene);
}

watchEffect(async (onCleanup) => {
  let outdate = false;
  const token = userStore.token;

  if (token) {
    isLoading.value = true;

    onCleanup(() => {
      outdate = true;
    });

    try {
      await Promise.all([
        userStore.FETCH_PROFILE(),
        // userStore.FETCH_PERMISSIONS(),
        globalStore.FETCH_MENUS(),
        globalStore.FETCH_COVER_FLAG(),
      ]);

      if (!outdate) {
        isLoading.value = false;
      }
    } catch (e) {
      if (!outdate) {
        isLoading.value = false;
      }
    }

    isLoading.value = false;
  }
});
</script>
<style>
#app .arco-card {
  border-radius: 12px !important;
}
</style>
