import { defineRule } from "vee-validate";
import {
  required,
  email,
  max,
  length,
  confirmed,
  min_value,
  numeric,
} from "@vee-validate/rules";

defineRule("required", (...arg: Parameters<typeof required>) =>
  required(...arg) ? true : "必填"
);
defineRule("email", (value: unknown) =>
  email(value) ? true : "无效的邮箱地址"
);
defineRule("max", (...arg: Parameters<typeof max>) =>
  max(...arg) ? true : "超过最大值"
);
defineRule("length", (...arg: Parameters<typeof length>) =>
  length(...arg) ? true : "长度不符合要求"
);
defineRule("confirmed", (...arg: Parameters<typeof confirmed>) =>
  confirmed(...arg) ? true : "两次输入不一致"
);
defineRule("phoneNumber", (value?: string) => {
  if (!value || !/^[1][0-9]{10}$/.test(value)) {
    return "无效手机号";
  }

  return true;
});
defineRule("taxId", (value?: string) => {
  if (
    value &&
    (/[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(value) ||
      /^[A-Za-z0-9]\w{14}$/g.test(value))
  ) {
    return true;
  }
  return "无效纳税人识别号";
});
defineRule("min_value", min_value);
defineRule("numeric", (...arg: Parameters<typeof numeric>) =>
  numeric(...arg) ? true : "请不要输入数字以外的字符"
);
